import React, { useEffect, useRef, useState } from "react"
import "./ReadMore.scss"
import parse from "html-react-parser"
import clsx from "clsx"
import $ from "jquery"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const ReadMore = ({ content, height, className }) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMore, setReadMore] = useState(true)
  const maxHeight = height || 260

  const { isMobile, isTablet } = useDeviceMedia()

  const elementRef = useRef()

  useEffect(() => {
    if (elementRef.current.clientHeight > maxHeight) {
      setShowReadMore(true)
    }

    if (readMore) {
      elementRef.current.style.maxHeight = maxHeight + "px"
    } else {
      elementRef.current.style.maxHeight = ""
    }
  }, [readMore])

  const handleClick = () => {
    if (!readMore) {
      var offSet = isMobile ? 90 : isTablet ? 130 : 160
      $('html, body').animate({
        scrollTop: $('#contentsection').offset()?.top - offSet
      });
    }
  }

  if (!content) return null

  return (
    <div className={clsx("read-more-wrap", className)}>
      <div
        ref={elementRef}
        className={clsx({
          "read-more-section": showReadMore,
          "read-more": readMore,
        })}
      >
        {parse(content)}
      </div>
      {showReadMore && (
        <button
          onClick={() => {setReadMore(val => !val); handleClick()}}
          className={`read-more-button ${readMore ? "" : "read-less"}`}
        >
          {readMore ? "Continue Reading" : "Read less"}
        </button>
      )}
    </div>
  )
}

export default ReadMore
