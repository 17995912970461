import React from "react"
import { getSrc } from "gatsby-plugin-image"
import { companyName } from "../../../site/constants"

const GetGatsbyImage = props => {
  var imagesource = props.image.url_sharp
  return (
    <img
      src={getSrc(imagesource)}
      alt={
        props.image.alternativeText
          ? props.image.alternativeText + companyName
          : props.fallbackalt + companyName
      }
      className={props.className}
    />
  )
}
export default GetGatsbyImage
