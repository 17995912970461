import React, { useRef } from "react"
import { formTracking, SendMail } from "../common/site/utils"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG_CAREER } from "./config"
import FileInput from "./elements/FileInput"
import Form from "./elements/Form"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import Recaptcha from "./elements/Recaptcha"

import { careersSchema, careersBannerSchema } from "./schemas"

const CarrerForm = props => {
  const careerbanner = props.careerbanner
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = careerbanner
        ? { ...data, ...FORM_CONFIG.careersBannerSchema }
        : { ...data, ...FORM_CONFIG.careers }
      let formData = new FormData()

      if (postData.attachment) {
        formData.append(
          "files.attachment",
          postData.attachment[0],
          postData.attachment[0].name
        ) // appending file
        delete postData.attachment
      }

      formData.append("data", JSON.stringify(postData))

      recaptchaRef.current.reset()
      postFormData(formData).then(async apiRes => {
        postData["file_url"] = `${apiRes?.form?.attachment?.url}`
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.careers.event_tracking,
        form_name: FORM_CONFIG.careers.form_name,
      })
    }
  }

  return (
    <>
      <Form
        className={
          careerbanner
            ? "custom-modal-form career-banner"
            : "base-form general-enquiry-form carrer-form"
        }
        validationSchema={careerbanner ? careersBannerSchema : careersSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG_CAREER}
      >
        <TextInput name="firstname" label="First Name" />
        <TextInput name="lastname" label="Last Name" />
        <TextInput name="email" label="Email Address" type="email" />
        <TextInput name="telephone" label="Phone Number" type="number" />

        <FileInput
          name="attachment"
          label="Attach your CV"
          accept=".pdf,.doc"
        />

        <TextAreaInput name="message" placeholder="Message" />
        <button className="button button-black" type="submit">
          submit details
        </button>
        <TermsPolicySection />
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
    </>
  )
}

export default CarrerForm
