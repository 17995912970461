import { Link, graphql, useStaticQuery } from "gatsby"
import React, {useState, useEffect } from "react"
import { Container, Modal } from "react-bootstrap"
import "./BannerLanding.scss"
import googleLogo from "../../images/google.svg"
import starsImg from "../../images/stars.svg"
import reviewStarsImg from "../../images/review-with-stars.svg"
import SearchBox from "../SearchBox/SearchBox"
import useDeviceMedia from "../../hooks/useDeviceMedia"
// import balckPhoneImg from "../../images/black-phone.svg"
// import whatsappImg from "../../images/whatsapp.svg"
// import useCompanyInfo from "../../hooks/useCompanyInfo"
import { GetPageURL } from "../common/site/utils"
import useAutoPlayVideo from "../../hooks/useAutoPlayVideo"
//import GetGatsbyImage from "../common/site/GetGatsbyImage"
import { listYourProperty } from "../../site/urls"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
  fadeInFromRight,
  fadeInFromLeft,
} from "../utils/animation"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import CarrerForm from "../forms/CarrerForm"
import wordsToNumbers from 'words-to-numbers';
//import axios from "axios"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BannerLanding = ({ bannerData,page_class,GQLPage,Header_Type }) => {
  const menuData = GQLPage?.menu
  const { banner_image, banner_video, title, title2, show_search,button_cta_1,button_cta_2,mobile_banner_video,mobile_banner_image } = bannerData
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(()=>{
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  },[])
  
  const reviewData = useStaticQuery(graphql`
    {
      greviews {
        reviews {
          id
          starRating
        }
      }
    }
  `)
  const videoRef = useAutoPlayVideo()
  const { isMobile, isTablet } = useDeviceMedia()
 // const { phone } = useCompanyInfo()

  const [showModal, setShowModal] = useState(false)
  //const [testimonials, setTestimonials] = useState([])
  const token = process.env.GATSBY_STRAPI_FORM_TOKEN

  const handleJobOppenings = () => {
    const getId = document.getElementById("job-oppening-component")
    getId.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const handleModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  // const getitems = async url => {
  //   try {
  //     const { data } = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         // "Content-Type": "multipart/form-data"
  //       },
  //     }) // could be from env files
  //     setTestimonials(data)
  //   } catch (error) {
  //     // cache it if fail/error;
  //     console.error(error)
  //   }
  //}
  // useEffect(() => {    
  //   let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
  //   getitems(url)
  // }, [])
 
  var testimonial_count = (reviewData && reviewData.greviews && reviewData.greviews.reviews) ? reviewData.greviews.reviews.length : 0;
  var rating_count = 0;
  reviewData && reviewData.greviews && reviewData.greviews.reviews.length > 0 && reviewData.greviews.reviews.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })

  const rating_avg = (rating_count != 0) ? rating_count / testimonial_count : null;
  const reviewCount = testimonial_count
  
  return (
    <>
    {GQLPage?.alias!=="Home"&&
    <Breadcrumbs menuData={menuData} Header_Type={Header_Type}/>
      }
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className=""
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          
          <div className={`banner-landing-wrapper ${page_class}`}>
            {isMobile && (mobile_banner_video||mobile_banner_image)?
            <>
              {
                 mobile_banner_video?.url && renderComponent ? (
                  <video
                    src={mobile_banner_video?.url}
                    alt="banner-img"
                    className="banner-img"
                    ref={videoRef}
                    autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true"
                    // controls={false}
                    // muted={true}
                    // playsInline
                    // autoPlay={true}
                    // preLoad="auto"
                    // loop
                    // type="video/mp4" 
                  />
                ) : ( <>
                   {renderComponent && <GatsbyImage image={getImage(mobile_banner_image?.url_sharp)} alt="Banner image" className="banner-img" />}</>
                )}
              
            </>
            : <>
            {banner_video && renderComponent ? (
              <video
                src={banner_video.url}
                alt="banner-img"
                className="banner-img"
                muted
                ref={videoRef}
                loop
                playsInline
                autoPlay
              />
            ) : ( <>
             {renderComponent && <GatsbyImage image={getImage(banner_image?.url_sharp)} alt="Banner image" className="banner-img" /> }</>
            )}
            </>
            }
            <div className="overlay-bg" />
            <div className="banner-landing-container">
              <Container className="banner-content">
                <motion.h1
                  className={`banner-title two-titles ${title2?"":"no-title-two"}`}
                  variants={fadeInFromLeft}
                >
                  <span className="title-1">{title}</span>
                  {title2&&
                  <span className="title-2">{title2}</span>
                  }
                </motion.h1>
                {show_search &&  (
                  <motion.div
                    className="banner-search"
                    variants={fadeInFromRight}
                  >
                    <SearchBox />
                  </motion.div>
                )}
                 {show_search && (
                <motion.div
                  className="valuation-section"
                  variants={fadeInFromTop}
                >
                  <p className="section-text">
                    Do you need to Sell or Rent your Property?
                  </p>
                  <Link to={listYourProperty} className="cta-link cta-link-white">
                    <span>List Your Property</span>
                    <i className="icon white-arrow-up-right" />
                  </Link>
                </motion.div>
                 )}


                {button_cta_1 || button_cta_2 ? (
                  <div className="cta-section">
                    {button_cta_1 &&
                      button_cta_1.label === "view job openings" && (
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleJobOppenings()}
                          className="button button-black"
                        >
                          {button_cta_1.label}
                        </a>
                      )}
                    {button_cta_1 &&
                      button_cta_1.label !== "view job openings" && (
                        <Link
                          to={GetPageURL(
                            button_cta_1.menu_item,
                            button_cta_1.custom_link
                          )}
                          className="button button-black"
                        >
                          {button_cta_1.label}
                        </Link>
                      )}
                    {button_cta_2 &&
                      button_cta_2.label === "send us your cv" && (
                        <a
                          href="javascript:void()"
                          className="button button-black"
                          onClick={() => handleModal()}
                        >
                          {button_cta_2.label}
                        </a>
                      )}
                    { renderComponent &&
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="more-filters-modal stay-updated team-listing-modal"
                        id="filter-modal"
                        // fullscreen={"xl-down"}
                      >
                        <button
                          className="stay-button-form team-listing-form"
                          type="button"
                          onClick={() => closeModal()}
                        >
                          <i className="icon cancel-icon" />
                        </button>
                        <Modal.Body>
                          <div className="modal-body-container carrer-body">
                            <h3>Get your Job</h3>
                            <CarrerForm careerbanner={true} />
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {button_cta_2 &&
                      button_cta_2.label !== "send us your cv" && (
                        <Link
                          to={GetPageURL(
                            button_cta_2.menu_item,
                            button_cta_2.custom_link
                          )}
                          className="button button-black"
                        >
                          {button_cta_2.label}
                        </Link>
                      )}
                  </div>
                ) : (
                  ""
                )}

              </Container>
            </div>
            <div className="banner-reviews-section-wrap">
              <Container className="banner-reviews-section">
                <img
                  src={googleLogo}
                  alt="google-logo"
                  className="google-logo"
                />
                <img
                  src={isMobile ? starsImg : reviewStarsImg}
                  alt="stars-logo"
                  className="stars-logo"
                />
                {rating_avg&&
                <p className="review">
                  <span>{rating_avg?.toFixed(1)}/5</span>
                  {!isMobile && ` from ${reviewCount} Reviews`}
                </p>
                }
              </Container>
              {/* {!isTablet && (
                <div className="social-section">
                  <a href={getWhatsAppURL(phone)} target="_blank">
                    <img
                      src={whatsappImg}
                      alt="social-whatsapp"
                      className="social-icon social-whatsapp"
                    />
                  </a>
                  <a href={"tel:" + phone}>
                    <img
                      src={balckPhoneImg}
                      alt="social-phone"
                      className="social-icon social-phone"
                    />
                  </a>
                </div>
              )} */}
            </div>
          </div>
        </motion.div>
      )}
    </InView>
    </>
  )
}

export default BannerLanding
