import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./UpComingRoadShow.scss"
import parse from "html-react-parser"
import CustomSlider from "../CustomSlider/CustomSlider"
import RoadshowCard from "../RoadshowCard/RoadshowCard"
import {
  inViewOptions2,
  containerVariants,  
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const UpComingRoadShows = (props) => {
  const data = useStaticQuery(graphql`
    query getupcommingRoadShows {
      glstrapi {
        roadShows (sort: "from_date:asc") {
          ...roadShowDetails
        }

        globalModule {
          upcoming_roadshows {
            description
            main_title
            main_description
            title
            cta {
              menu_item {
                link_type
                label
                slug
              }
            }
          }
          id
        }
      }
    }
  `)

  const roadshowData = data.glstrapi.roadShows
  const staticData = data.glstrapi.globalModule.upcoming_roadshows
  const roadshowUrl = staticData.cta?.menu_item.slug

  const upcomingRoadShows = roadshowData?.filter(
    roadShow => new Date(roadShow.from_date).getTime() > new Date().getTime()
  )

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <>
            <div className="upcoming-roadshow-wrap section-p">
              <Container className="upcoming-roadshow-container">
                <h2>{staticData.main_title}</h2>
                <p className="up-desc">{staticData.main_description}</p>
                <CustomSlider size={"md"} darkMode length={upcomingRoadShows?.length}>
                  {upcomingRoadShows?.slice(0,5).map((data, i) => (
                    <RoadshowCard roadshow={data} isModule={props.isModule}/>
                  ))}
                </CustomSlider>
                <div className="horizontal-line"></div>

                <motion.div
                  className="attend-roadshows"
                  variants={fadeInFromTop}
                >
                  <h2>{staticData.title}</h2>
                  <div className="roadshows-desktop-xl">
                    {staticData.description && (
                      <p className="road-view-desc">{parse(staticData.description)}</p>
                    )}
                    <div className="roadshow-page-link">
                      <Link
                        to={`/${roadshowUrl}/`}
                        className="cta-link cta-link-white"
                      >
                        <span> Find out more</span>
                        <i className="icon white-arrow-up-right" />
                      </Link>
                    </div>
                  </div>
                </motion.div>
              </Container>
            </div>
          </>
        </motion.div>
      )}
    </InView>
  )
}

export default UpComingRoadShows
