import React, { useRef, useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./FormContentComponent.scss"
import clsx from "clsx"
import { getThemeClass } from "../common/site/utils"
import MarketInsights from "../forms/MarketInsights"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromLeft,
  fadeInFromRight,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import GetGatsbyImage from "../common/site/GetGatsbyImage"

const FormContentComponent = props => {
  const { description, form_type, images, title, form_theme,file_upload } = props.module
  const alias = props?.alias?.replace(' ', '')

  const pdfUrl=file_upload?.url?file_upload?.url:""
  const LivingEmail = alias === 'holidayhomes' ? process.env.GATSBY_ALIVING_MAIL_TO : ''

  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(()=>{
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  },[])

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div
            className={clsx("form-textContent-wrap", getThemeClass(form_theme))}
          >
            <Container className="form-textContent-container">
              <section className="left-right-container">
                <motion.div
                  className="left-container"
                  variants={fadeInFromLeft}
                >
                  <h2>{title}</h2>
                  {description && <p>{parse(description)}</p>}

                  <div>
                    {renderComponent && 
                    <MarketInsights to_email_id={LivingEmail} pdfUrl={pdfUrl}/>
                    }
                  </div>
                </motion.div>
                {images.length > 0 && (
                  <motion.div
                    className="right-container"
                    variants={fadeInFromRight}
                  >
                    {images.map((image, i) => {
                      return (
                      // <img src={image.url} alt={title} />
                      <GetGatsbyImage
                      image={image}
                      fallbackalt={"banner-img"}
                      className="banner-img"
                    />
                      )
                    })}
                  </motion.div>
                )}
              </section>
            </Container>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default FormContentComponent
