import { format } from "date-fns"
import React,{useState} from "react"
import "./RoadshowCard.scss"
import { fadeInFromTop } from "../utils/animation"
import loadable from "@loadable/component";
import { motion } from "framer-motion"
import { Modal } from "react-bootstrap"
import GetGGFXImage from "../common/site/GetGGFXImage"
const RoadShowEnquiry = loadable(() => import("../forms/RoadShowEnquiry"));

const RoadshowCard = ({ roadshow,isModule }) => {
 const isUpcoming = new Date(roadshow.from_date).getTime() > new Date().getTime();

  const [showModal,setShowModal]=useState(false)

  if (!roadshow) return null


  const handleShow=()=>{
    setShowModal(true)
  }

  const closeModal=()=>{
    setShowModal(false)
  }

  const isSameDate =
    format(new Date(roadshow?.from_date), "d MMMM yyy") ===
    format(new Date(roadshow?.to_date), "d MMMM yyy")

  let processedImages = roadshow?.imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <motion.div className="roadshow-card" variants={fadeInFromTop}>
            <GetGGFXImage
              imagename={"road-shows.image.tileimg"}
              imagesource={roadshow?.image}
              fallbackalt={roadshow?.place}
              imagetransformresult={processedImages}
              id={roadshow?.id}
              className="roadshow-img"
            />
      <p className="roadshow-place">{roadshow.place}</p>
      {isSameDate ? (
        <p className="roadshow-dates">
          {format(new Date(roadshow?.from_date), "d MMMM yyy")}
        </p>
      ) : roadshow?.from_date ? (
        <p className="roadshow-dates">
          {format(new Date(roadshow?.from_date), "d") +
            "-" +
            format(new Date(roadshow?.to_date), "d MMMM yyy")}
        </p>
      ) : (
        ""
      )}

      {isUpcoming&&!isModule&&
        <p onClick={handleShow} className="enquiry-text">Enquire Now</p>
      }

                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      className="more-filters-modal stay-updated team-listing-modal"
                      id="filter-modal"
                      // fullscreen={"xl-down"}
                    >
                      <button
                        className="stay-button-form team-listing-form"
                        type="button"
                        onClick={() => closeModal()}
                      >
                        <i className="icon cancel-icon" />
                      </button>
                      <Modal.Body>
                        <div className="modal-body-container carrer-body">
                          <h3>RoadShow Enquiry</h3>
                          <RoadShowEnquiry city={roadshow.place} isModalshow/>
                        </div>
                      </Modal.Body>
                    </Modal>
    </motion.div>
  )
}

export default RoadshowCard
