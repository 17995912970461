import React, { useEffect, useRef, useState } from "react"
import "./ContentWIthStatsModule.scss"
import { Link } from "gatsby"
import { GetPageURL, scrollToElement } from "../common/site/utils"
import _ from "lodash"
import AwardSlider from "../AwardSlider/AwardSlider"
import { Container } from "react-bootstrap"
import clsx from "clsx"
import ReadMore from "../ReadMore/ReadMore"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromLeft,
  fadeInFromRight,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const ContentWIthStatsModule = ({ module }) => {
  const {
    title,
    content_stat_theme,
    stats,
    select_collection,
    description,
    cta,
  } = module

  const theme = content_stat_theme
  const { isMobile, isTablet, isLargeScreen } = useDeviceMedia()

  // const goToEnquiry = () => {
  //   const element = document.getElementById("contentsection")
  //   var scrollOffset = isMobile ? -40 : isTablet ? -50 : 120
  //   if (element) {
  //     scrollToElement(element, scrollOffset)
  //   }
  // }

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div
            className={clsx(
              "content-module-wrap",
              theme === "white" || theme == null ? "section-m" : "section-p",
              {
                "grey-bg": theme === "grey",
                "black-bg": theme === "black",
              }
            )}
          >
            <Container className="content-module-container">
              <div className="content-section" id="contentsection">
                <motion.div className="left-section" variants={fadeInFromLeft}>
                  <h2 className="title">{title}</h2>
                  <ReadMore
                    content={description}
                    height={260}
                    className={"description"}
                  />
                  {cta && (
                    <div className="cta-section">
                      <Link
                        to={GetPageURL(cta.menu_item, cta.custom_link)}
                        className="cta-link cta-link-black"
                      >
                        {cta.label}
                      </Link>
                    </div>
                  )}
                </motion.div>
                <motion.div
                  className="right-section"
                  variants={fadeInFromRight}
                >
                  {!_.isEmpty(stats) &&
                    stats.map(stat => (
                      <div className="stat-item">
                        <p className="stat-title">
                          {isLargeScreen && stat?.stat?.includes("billion")
                            ? stat.stat?.replace("billion", "bn")
                            : stat.stat}
                        </p>
                        <p className="stat-description">{stat.description}</p>
                      </div>
                    ))}
                </motion.div>
              </div>
              {theme !== null && <div className="divider"></div>}
              {select_collection &&
                select_collection.choose_collection === "awards_slider" && (
                  <AwardSlider module={select_collection} />
                )}
            </Container>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default ContentWIthStatsModule
