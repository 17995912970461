import React, { useRef, useEffect, useState } from "react"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import { marketInsightsSchema } from "./schemas"
import "./Form.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Recaptcha from "./elements/Recaptcha"
import { formTracking, SendMail } from "../common/site/utils"

const MarketInsights = ({ to_email_id,pdfUrl }) => {
  const { isMobile } = useDeviceMedia()
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(()=>{
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  },[])

  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.market_insights }
      if (to_email_id) {
        postData["to_email_id"] = to_email_id
      }
      if(pdfUrl){
        postData["pdf_url"] = pdfUrl
      }
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.market_insights.event_tracking,
        form_name: FORM_CONFIG.market_insights.form_name,
      })
    }
  }
  return (
    <>
      <Form
        className="form-enquiry base-form"
        validationSchema={marketInsightsSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput name="firstname" placeholder="Name" />
        <TextInput name="email" placeholder="Email" />
        <TextInput name="telephone" placeholder="Mobile Number" type="number" />
        <button className="button button-black" type="submit">
          {isMobile ? "Get your Report" : <i className="icon right-arrow" />}
        </button>
      </Form>
      <TermsPolicySection />
      <div className="d-none">
      {renderComponent && <Recaptcha recaptchaRef={recaptchaRef} />}
      </div>
    </>
  )
}

export default MarketInsights
