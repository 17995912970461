import clsx from "clsx"
import React, { useState,useEffect } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import { getStrapiDataFromURL, getThemeClass } from "../common/site/utils"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./FeaturedProperties.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
  fadeInFromLeft,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Link } from "gatsby"
import GetGGFXImage from "../common/site/GetGGFXImage"
import _ from "lodash"

const KEYS = {
  sale: "for-sale",
  rent: "for-rent",
  offplan: "off-plan",
}

const FeaturedProperties = ({ module }) => {

  const [key, setKey] = useState(KEYS.sale)

  const [saleLists, setSaleLists] = useState([])
  const [rentLists, setRentLists] = useState([])
  const [newHomesLists, setnewHomesLists] = useState([])

  const { title, featured_theme } = module

  const getSaleItems = async url => {
    const featuredProperties = await getStrapiDataFromURL(url)
    setSaleLists(featuredProperties)
  }

  const getRentItems = async url => {
    const featuredProperties = await getStrapiDataFromURL(url)
    setRentLists(featuredProperties)
  }

  const getNewHomesItems = async url => {
    const featuredProperties = await getStrapiDataFromURL(url)
    setnewHomesLists(featuredProperties)
  }

  useEffect(() => {
    let saleUrl =
      `/stb-lists/item/featured_sales?publish=true`
    getSaleItems(saleUrl)
    let rentUrl=
    `/stb-lists/item/featured_rent?publish=true`
    getRentItems(rentUrl)
    
    let newHomesUrl=
    `/stb-lists/item/featured_offplan?publish=true`
    getNewHomesItems(newHomesUrl)
  
}, [])

  const PropertyCard = ({
    image,
    type,
    price,
    address,
    description,
    search_type,
    department,
    id,
    slug,
    imagetransforms,
  }) => {
    let uriStr = ""
    if (search_type === "sales" && department === "residential") {
      uriStr = `property-for-sale`
    } else if (search_type === "lettings" && department === "residential") {
      uriStr = `property-for-rent`
    } else if (search_type === "sales" && department === "new_developments") {
      uriStr = `off-plan-property-for-sale`
    }

    let processedImages = imagetransforms?.images_Transforms
    processedImages ||= JSON.stringify({})

    return (
      <motion.div className="featured-property-card" variants={fadeInFromTop}>
        <Link to={`/${uriStr}/${slug}-${id}`}>
          <div className="image-section">
            <GetGGFXImage
              imagename={"property.images.tileimg"}
              imagesource={image}
              fallbackalt={description}
              imagetransformresult={processedImages}
              id={id}
              className="property-image"
            />
            <div className="image-tag">
              <p className="tag-name">{type}</p>
            </div>
          </div>
        </Link>
        <div className="content">
        <Link to={`/${uriStr}/${slug}-${id}`}>
          <p className="price">AED {parseFloat(price).toLocaleString()}</p>
          </Link>
          <Link to={`/${uriStr}/${slug}-${id}`}>
          <p className="address">{address}</p>
          </Link>
          <p className="description">{description}</p>
        </div>
      </motion.div>
    )
  }


  const SliderTab = ({ items }) => {
    if (_.isEmpty(items)) return null

    return (
      <CustomSlider className="featured-slider-wrap" length={items?.length}>
        {items.map(property => (
          <PropertyCard
            address={property.display_address}
            description={property.description}
            image={property.images[0]}
            price={property.price}
            type={property.building[0]}
            key={property.id}
            search_type={property.search_type}
            department={property.department}
            slug={property.slug}
            id={property.id}
            imagetransforms={property.imagetransforms}
          />
        ))}
      </CustomSlider>
    )
  }

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div
            className={clsx(
              "featured-properties-wrap",
              getThemeClass(featured_theme)
            )}
          >
            <Container className="featured-properties-container">
              <motion.h2 variants={fadeInFromLeft} className="title">
                {title}
              </motion.h2>
              <Tabs
                activeKey={key}
                onSelect={k => setKey(k)}
                className="featured-properties-tab-section"
              >
                <Tab eventKey={KEYS.sale} title="For Sale">
                  <SliderTab items={saleLists} />
                </Tab>
                <Tab eventKey={KEYS.rent} title="For Rent">
                  <SliderTab items={rentLists} />
                </Tab>
                {newHomesLists?.length > 0 &&
                  <Tab eventKey={KEYS.offplan} title="Offplan">
                    <SliderTab items={newHomesLists} />
                  </Tab>
                }
              </Tabs>
            </Container>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default FeaturedProperties
