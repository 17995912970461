import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import AwardCard from "../AwardCard/AwardCard"
import SliderModule from "../SliderModule/SliderModule"
import "./AwardSlider.scss"
import { fadeInFromTop } from "../utils/animation"

import { motion } from "framer-motion"

const AwardSlider = ({ module }) => {
  const data = useStaticQuery(graphql`
    query getAwardsSliderData {
      glstrapi {
        awards( limit:8) {
          ...awardDetails
        }
      }
    }
  `)

  const awards = data.glstrapi.awards

  if (!awards) return null

  const sliderData = awards.map(award => <AwardCard award={award} />)

  return (
    <motion.div className="areaguide-slider-module" variants={fadeInFromTop}>
      <SliderModule
        title={module.title}
        description={module.description}
        sliderData={sliderData}
        className="areaguide-slider-wrap"
        sliderClassName="areaguide-slider-section"
        theme={module.theme}
        size="xl"
        noContainer
      />
    </motion.div>
  )
}

export default AwardSlider
