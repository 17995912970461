import { format } from "date-fns/esm"
import React from "react"
import "./AwardCard.scss"
import GetGGFXImage from "../common/site/GetGGFXImage"

const AwardCard = ({ award }) => {
  const formattedDate = format(new Date(award.date), "y")

  let processedImages = award.imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div className="award-card">
      {/* <img src={award.image.url} alt="" className="award-img" /> */}
      <GetGGFXImage
        imagename={"award.image.tileimg"}
        imagesource={award.image}
        fallbackalt={award.name}
        imagetransformresult={processedImages}
        id={award.id}
        className="award-img"
      />
      <div className="award-content">
        <p className="award-title">{award.name}</p>
        <p className="award-date">{formattedDate}</p>
      </div>
    </div>
  )
}

export default AwardCard
